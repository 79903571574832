import { lazy } from "react";

const ArticleDetailContainer = lazy(() => import("@energylab/shared-components/modules/news/articleDetail/articleDetailContainer"));
const ArticlesContainer = lazy(() => import("@energylab/shared-components/modules/news/articles/articlesContainer"));
const ArticleTypeContainer = lazy(() => import("@energylab/shared-components/modules/news/articleType/articleTypeContainer"));

const ROUTES = {
    locales: {
        nl: "nieuws"
    },
    path: "news",
    children: [
        {
            path: "article/:contentId",
            element: <ArticleDetailContainer />
        },
        {
            path: "tags/:contentTagId",
            children: [
                {
                    path: ":contentId",
                    element: <ArticleDetailContainer />
                },
                {
                    index: true,
                    element: <ArticleTypeContainer />
                }
            ]
        },
        {
            index: true,
            element: <ArticlesContainer />
        }
    ]
};

export default ROUTES;
