const unsortedEntities = [
    {
        value: "Russia", // OK
        label: "Russia", // OK
        children: [
            { label: "BSCIS - BS RUSSIA", value: "BSCIS - BS RUSSIA" },
            { label: "BMCIS - ULYANOVSK", value: "BMCIS - ULYANOVSK" }
        ]
    },
    {
        value: "Turkey", // OK
        label: "Turkey", // OK
        children: [
            { label: "BRISA BRIDGESTONE SABANCI LASTIK SANAYI VE TICARET A.S.", value: "BRISA BRIDGESTONE SABANCI LASTIK SANAYI VE TICARET A.S." }
        ]
    },
    {
        value: "South Africa", // OK
        label: "South Africa", // OK
        children: [
            { label: "BSAF BRIDGESTONE SOUTH AFRICA - Brits Plant", value: "BSAF BRIDGESTONE SOUTH AFRICA - Brits Plant" },
            { label: "BSAF BRIDGESTONE SOUTH AFRICA - Port Elizabeth Plant", value: "BSAF BRIDGESTONE SOUTH AFRICA - Port Elizabeth Plant" },
            { label: "BSAF BRIDGESTONE SOUTH AFRICA - (All non-plant employees)", value: "BSAF BRIDGESTONE SOUTH AFRICA - (All non-plant employees)" },
            { label: "BSAR SUPA QUICK RETAIL GROUP", value: "BSAR SUPA QUICK RETAIL GROUP" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Spain", // OK
        label: "Spain", // OK
        children: [
            { label: "BSHP - BS SPAIN", value: "BSHP - BS SPAIN" },
            { label: "RTLHP - ABASTECEDORA BALEA", value: "RTLHP - ABASTECEDORA BALEA" },
            { label: "RTLHP - FIRSTSTOP SOUTHWEST", value: "RTLHP - FIRSTSTOP SOUTHWEST" },
            { label: "BSHPM-BILBAO", value: "BSHPM-BILBAO" },
            { label: "BSHPM-BURGOS", value: "BSHPM-BURGOS" },
            { label: "BSHPM-PSM", value: "BSHPM-PSM" },
            { label: "BSHPM - USANSOLO", value: "BSHPM - USANSOLO" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Hungary", // OK
        label: "Hungary", // OK
        children: [
            { label: "BSHU - BS TATABANYA", value: "BSHU - BS TATABANYA" },
            { label: "BSHUS - BS BUDAPEST", value: "BSHUS - BS BUDAPEST" }
        ]
    },
    {
        value: "Poland", // OK
        label: "Poland", // OK
        children: [
            { label: "BSSTM - BS STARGARD", value: "BSSTM - BS STARGARD" },
            { label: "BSPZM - BS POZNAN", value: "BSPZM - BS POZNAN" },
            { label: "SCC - BS SHARED SERVICES CENTER", value: "SCC - BS SHARED SERVICES CENTER" },
            { label: "BSPS - BS POLAND", value: "BSPS - BS POLAND" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Czech Republic", // OK
        label: "Czech Republic", // OK
        children: [
            { label: "BSCR - BS CZECH REPUBLIC", value: "BSCR - BS CZECH REPUBLIC" }
        ]
    },
    {
        value: "Latvia", // OK
        label: "Latvia", // OK
        children: [
            { label: "BSBA - BS BALTICS", value: "BSBA - BS BALTICS" }
        ]
    },
    {
        value: "Slovakia", // OK
        label: "Slovakia", // OK
        children: [
            { label: "BSSK - BS SLOVAKIA", value: "BSSK - BS SLOVAKIA" }
        ]
    },
    {
        value: "Germany", // OK
        label: "Germany", // OK
        children: [
            { label: "Bridgestone Deutschland - BSDL", value: "Bridgestone Deutschland - BSDL" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Austria", // OK
        label: "Austria", // OK
        children: [
            { label: "Bridgestone Austria - BSAU", value: "Bridgestone Austria - BSAU" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Switzerland", // OK
        label: "Switzerland", // OK
        children: [
            { label: "BRIDGESTONE SCHWEIZ - BSSZ", value: "BRIDGESTONE SCHWEIZ - BSSZ" },
            { label: "RTLSZ - FIRST STOP REIFEN", value: "RTLSZ - FIRST STOP REIFEN" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "France", // OK
        label: "France", // OK
        children: [
            { label: "BSFR - MASSY", value: "BSFR - MASSY" },
            { label: "BSFR - BETHUNE", value: "BSFR - BETHUNE" },
            { label: "RTDFR - SBLR", value: "RTDFR - SBLR" },
            { label: "RTLFR - METIFIOT SAS & FIRST STOP FRANCE", value: "RTLFR - METIFIOT SAS & FIRST STOP FRANCE" },
            { label: "COTE ROUTE/AYME", value: "COTE ROUTE/AYME" },
            { label: "SPEEDY", value: "SPEEDY" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Belgium", // OK
        label: "Belgium", // OK
        children: [
            { label: "BSEMEA - BS EUROPE", value: "BSEMEA - BS EUROPE" },
            { label: "BSBL-ZAVENTEM", value: "BSBL-ZAVENTEM" },
            { label: "BSLE-ZEEBRUGGE", value: "BSLE-ZEEBRUGGE" },
            { label: "FSTBL - FIRSTSTOP BELGIUM", value: "FSTBL - FIRSTSTOP BELGIUM" },
            { label: "FSTEU - FIRST STOP EUROPE", value: "FSTEU - FIRST STOP EUROPE" },
            { label: "MM - MECAMOLD SAS", value: "MM - MECAMOLD SAS" },
            { label: "BDGEN - BANDAG EUROPE NV", value: "BDGEN - BANDAG EUROPE NV" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Italy", // OK
        label: "Italy", // OK
        children: [
            { label: "BSIT - BS ITALY", value: "BSIT - BS ITALY" },
            { label: "MANUFACTURING - BSITM - BS BARI", value: "MANUFACTURING - BSITM - BS BARI" },
            { label: "TECHNICAL CENTER - ROME", value: "TECHNICAL CENTER - ROME" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "United Kingdom", // OK
        label: "United Kingdom", // OK
        children: [
            { label: "BSUK - BS UK", value: "BSUK - BS UK" },
            { label: "RTDUK - BULLDOG", value: "RTDUK - BULLDOG" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Sweden", // OK
        label: "Sweden", // OK
        children: [
            { label: "BSSW - BS SWEDEN", value: "BSSW - BS SWEDEN" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Denmark", // OK
        label: "Denmark", // OK
        children: [
            { label: "BSDK - BS DENMARK", value: "BSDK - BS DENMARK" },
            { label: "RTDDK - TOBØL DÆK A/S", value: "RTDDK - TOBØL DÆK A/S" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Finland", // OK
        label: "Finland", // OK
        children: [
            { label: "BSFN - BS FINLAND", value: "BSFN - BS FINLAND" }
        ]
    },
    {
        value: "Ireland", // OK
        label: "Ireland", // OK
        children: [
            { label: "BSIE - BS IRELAND (GB)", value: "BSIE - BS IRELAND (GB)" },
            { label: "BSIE - BS IRELAND", value: "BSIE - BS IRELAND" },
            { label: "RTLIE - FIRST STOP LEVET", value: "RTLIE - FIRST STOP LEVET" }
        ]
    },
    {
        value: "Botswana", // OK
        label: "Botswana", // OK
        children: [
            { label: "BSAF BRIDGESTONE SOUTH AFRICA", value: "BSAF BRIDGESTONE SOUTH AFRICA" },
            { label: "BSAR SUPA QUICK RETAIL GROUP", value: "BSAR SUPA QUICK RETAIL GROUP" }
        ]
    },
    {
        value: "Bulgaria", // OK
        label: "Bulgaria", // OK
        children: [
            { label: "BSBU - BS BULGARIA", value: "BSBU - BS BULGARIA" }
        ]
    },
    {
        value: "Egypt", // OK
        label: "Egypt", // OK
        children: [
            { label: "BSMEA", value: "BSMEA" }
        ]
    },
    {
        value: "Iran", // OK
        label: "Iran", // OK
        children: [
            { label: "BSMEA", value: "BSMEA" }
        ]
    },
    {
        value: "Jordan", // OK
        label: "Jordan", // OK
        children: [
            { label: "BSMEA", value: "BSMEA" }
        ]
    },
    {
        value: "Lebanon", // OK
        label: "Lebanon", // OK
        children: [
            { label: "BSMEA", value: "BSMEA" }
        ]
    },
    {
        value: "Morocco", // OK
        label: "Morocco", // OK
        children: [
            { label: "BSMEA", value: "BSMEA" }
        ]
    },
    {
        value: "Namibia", // OK
        label: "Namibia", // OK
        children: [
            { label: "BSAF BRIDGESTONE SOUTH AFRICA", value: "BSAF BRIDGESTONE SOUTH AFRICA" },
            { label: "BSAR SUPA QUICK RETAIL GROUP", value: "BSAR SUPA QUICK RETAIL GROUP" }
        ]
    },
    {
        value: "Netherlands", // OK
        label: "Netherlands", // OK
        children: [
            { label: "BDGBV-BORN", value: "BDGBV-BORN" },
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Nigeria", // OK
        label: "Nigeria", // OK
        children: [
            { label: "BSMEA", value: "BSMEA" }
        ]
    },
    {
        value: "Portugal", // OK
        label: "Portugal", // OK
        children: [
            { label: "BSPO - BS PORTUGAL", value: "BSPO - BS PORTUGAL" }
        ]
    },
    {
        value: "Romania", // OK
        label: "Romania", // OK
        children: [
            { label: "BSRO - BS ROMANIA", value: "BSRO - BS ROMANIA" }
        ]
    },
    {
        value: "Saudi Arabia", // OK
        label: "Saudi Arabia", // OK
        children: [
            { label: "BSMEA", value: "BSMEA" }
        ]
    },
    {
        value: "Swaziland", // OK
        label: "Swaziland", // OK
        children: [
            { label: "BSAF BRIDGESTONE SOUTH AFRICA", value: "BSAF BRIDGESTONE SOUTH AFRICA" },
            { label: "BSAR SUPA QUICK RETAIL GROUP", value: "BSAR SUPA QUICK RETAIL GROUP" }
        ]
    },
    {
        value: "UAE", // OK
        label: "UAE", // OK
        children: [
            { label: "BSMEA", value: "BSMEA" }
        ]
    },
    {
        value: "Zambia", // OK
        label: "Zambia", // OK
        children: [
            { label: "BSAF BRIDGESTONE SOUTH AFRICA", value: "BSAF BRIDGESTONE SOUTH AFRICA" }
        ]
    },
    {
        value: "Australia", // OK
        label: "Australia", // OK
        children: [
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Mexico", // OK
        label: "Mexico", // OK
        children: [
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "United States of America", // OK
        label: "United States of America", // OK
        children: [
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Chile", // OK
        label: "Chile", // OK
        children: [
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "Taiwan", // OK
        label: "Taiwan", // OK
        children: [
            { label: "Webfleet Solutions - WFS", value: "Webfleet Solutions - WFS" }
        ]
    },
    {
        value: "India",
        label: "India",
        children: []
    },
];

export const entities = unsortedEntities.sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;
}).map(item => {
    item.children.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        return 0;
    });

    return item;
});
