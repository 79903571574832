import { lazy } from "react";

const AmbassadorInfoContainer = lazy(() => import("@energylab/shared-components/modules/ambassadors/ambassadorInfo/ambassadorInfoContainer"));
const AmbassadorOverviewContainer = lazy(() => import("@energylab/shared-components/modules/ambassadors/ambassadorOverview/ambassadorOverviewContainer"));
const AmbassadorRedirectCheckContainer = lazy(() => import("@energylab/shared-components/modules/ambassadors/ambassadorRedirectCheck/ambassadorRedirectCheckContainer"));
const AmbassadorContainer = lazy(() => import("@energylab/shared-components/modules/ambassadors/ambassador/ambassadorContainer"));

export default {
    path: "halloffame",
    children: [
        {
            path: ":id/:tab",
            element: (
                <AmbassadorContainer
                    defaultTab="overview"
                    title="ambassador.title"
                    tabRoute="halloffame/:id/:tab"
                    tabs={[
                        {
                            tabName: "info",
                            navbarTextId: "charityPage.info",
                            component: (p) => <AmbassadorInfoContainer {...p} />
                        },
                        {
                            tabName: "overview",
                            navbarTextId: "charityPage.overview",
                            component: (p) => <AmbassadorOverviewContainer {...p} />
                        }

                    ]}
                />
            )
        },
        {
            index: true,
            element: <AmbassadorRedirectCheckContainer defaultTab="info" place="HoF" route="halloffame/:id/:tab"/>
        }
    ]
};