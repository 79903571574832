export const socialConfig = {
    disableInstagram: false,
    disableTwitter: false,
    disableFacebook: false,
    disableLinkedIn: false,
    disableOnChallenCard: false,
    hideFacebookOnCommunity: false,
    hideTwitterOnCommunity: false,
    hideInstagramOnCommunity: false,
    hideLinkedInOnCommunity: true
};
