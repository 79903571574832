import { ProfileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { UpdateProfileVariables } from "@energylab/shared-components/models/graphql/types";
import { profileStructure } from "../profileStructure";
import { routeConfig } from "./routeConfig";

export const profileConfig: ProfileConfig = {
    structure: profileStructure,
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "entity",
        "sex"
    ],
    formToValues: (values, originalValues): UpdateProfileVariables => {
        if (!originalValues) {
            throw Error("Can not update profile when not logged in");
        }

        let nickname: string | undefined;
        if (values.publicDisplayNameType === "nickname") {
            nickname = String(values.nickname);
        }

        return {
            profile: {
                ...originalValues,
                locale: String(values.locale) || routeConfig.defaultLocale,
                givenName: String(values.givenName),
                familyName: String(values.familyName),
                profile: {
                    ...originalValues.profile,
                    entity: String(values.entity),
                    birthDate: values.birthDate,
                    sex: values.sex,
                    nationality: values.nationality
                },
                privacy: {
                    ...originalValues.privacy,
                    publicDisplayNameType: String(values.publicDisplayNameType),
                    nickname: values.nickname ? nickname as string : undefined
                }
            }
        };
    },
    valuesToForm: (values) => {
        if (!values) {
            return {};
        }

        const profile = values.profile || {};
        const privacy = values.privacy || {};

        return {
            givenName: values.givenName,
            familyName: values.familyName,
            birthDate: profile.birthDate,
            sex: profile.sex,
            entity: Array.isArray(profile.entity) ? profile.entity : (profile.entity && profile.entity.split(",")),
            nationality: profile.nationality,
            email: values.email,
            publicDisplayNameType: privacy.publicDisplayNameType,
            nickname: privacy.nickname,
            locale: values.locale
        };
    }
};
