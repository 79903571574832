import { SeoConfig } from "@energylab/shared-components/constants/config/seoConfig";

export const seo: SeoConfig = {
    pageTitle: "B-Olympic",
    title: {
        nl: "B-Olympic",
        fr: "B-Olympic",
        en: "B-Olympic",
        de: "B-Olympic",
        es: "B-Olympic",
        pl: "B-Olympic",
        it: "B-Olympic",
        hu: "B-Olympic"
    },
    description: {
        nl: "",
        fr: "",
        en: ""
    },
    image: "",
    site: "",
    seoTitlePlacement: "left"
};
