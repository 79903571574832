import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { nationalities } from "@energylab/shared-components/constants/nationalities";
import * as React from "react";
import {FormatMessage} from "@energylab/shared-components/components/formatMessage/formatMessage"
import { routeConfig } from "./config/routeConfig";
import { entities } from "./entities";
import { sex } from "./sex";
import { minYearOld } from "environment";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";


export const profileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                {
                    title: "auth.fields.givenName",
                    placeholder: "auth.fields.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "auth.fields.familyName",
                    placeholder: "auth.fields.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                }
            ]
        },
        {
            type: "row",
            property: "nicknameOptions",
            formElements: [
                {
                    title: "auth.fields.publicDisplayNameType",
                    type: "select",
                    options: [
                        {
                            title: "Nickname", value: "nickname"
                        },
                        {
                            title: "Fullname", value: "fullname"
                        }
                    ],
                    defaultValue: "fullname",
                    property: "publicDisplayNameType",
                    required: false
                },
                {
                    title: "auth.fields.nickname",
                    placeholder: "auth.fields.nickname",
                    type: "text",
                    property: "nickname",
                    disableWhen: {
                        property: "publicDisplayNameType",
                        valueIs: "fullname"
                    },
                    requireWhen: {
                        property: "publicDisplayNameType",
                        valueIs: "nickname"
                    }
                }
            ]
        },
        {
            title: "auth.fields.birthDate",
            type: "date",
            property: "birthDate",
            required: true,
            disabled: false,
            validationRules: [
                {
                    validatorBuilder: checkMinBirthDateCreator(minYearOld)
                }
            ]
        },
        {
            title: "form.nationality",
            type: "select",
            options: nationalities.map(nat => (
                {
                    title: <FormatMessage id={`nationality.${nat.code}`} />,
                    value: nat.code
                }
            )),
            property: "nationality",
            required: false
        },
        {
            title: "form.entity",
            type: "cascader",
            property: "entity",
            options: entities,
            required: true
        },
        {
            title: "auth.fields.selectedLanguage",
            type: "select",
            options: routeConfig.locales.map(language => (
                {
                    title: <FormatMessage id={`language.${language}`} />,
                    value: language
                }
            )),
            property: "locale",
            required: false
        },
        {
            title: "form.sex",
            type: "radio",
            options: sex,
            property: "sex",
            required: true
        }
    ]
};
