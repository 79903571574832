import { lazy } from "react";
import * as environment from "environment";

const ArticleDetailContainer = lazy(() => import("@energylab/shared-components/modules/news/articleDetail/articleDetailContainer"));
const ContentContainer = lazy(() => import("@energylab/shared-components/modules/pages/content/contentContainer"));

const ROUTES = [
    {
        path: "beinspired",
        element: <ArticleDetailContainer contentId={environment["beInspiredContentId"]} />
    },
    {
        path: "movechallenge",
        element: <ArticleDetailContainer contentId={environment["moveChallengeContentId"]} />
    },
    {
        path: "themes",
        element: <ContentContainer type="challenge" />
    }
];

export default ROUTES;
