import { SocialShareConfig } from "@energylab/shared-components/constants/config/socialShareConfig";

export const socialShareConfig: SocialShareConfig = {
    enabled: true,
    copyClipboardEnabled: true,
    shareWithEmail: {
        enabled: true,
        translations: {
            Content: {
                subject: "likeAndComment.shareContentSubject",
                body: "likeAndComment.shareContentBody"
            },
            Challenge: {
                subject: "likeAndComment.shareChallengeSubject",
                body: "likeAndComment.shareChallengeBody"
            }
        }
    }

};
