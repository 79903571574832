export const navbarConfig = {
    fullWidth: false,
    navbarItems: [
        {
            id: "myActivities",
            translationKey: "menuItem.myActivities",
            redirectTo: { path: "myspace/activities/overview" }
        },
        {
            id: "beinspired",
            translationKey: "menuItem.challenge.beInspired",
            redirectTo: { path: "beinspired" }
        },
        {
            id: "challenges",
            translationKey: "menuItem.challenges",
            redirectTo: { path: "challenges" }
        },
        {
            id: "news",
            translationKey: "menuItem.news",
            redirectTo: { path: "news" }
        },
        {
            id: "leaderboard",
            translationKey: "menuItem.leaderboard",
            redirectTo: { path: "leaderboard" }
        },
        {
            id: "gallery",
            translationKey: "menuItem.gallery",
            redirectTo: { path: "gallery" }
        },
        {
            id: "charity",
            translationKey: "menuItem.charity",
            redirectTo: { path: "charity" }
        },
        {
            id: "hallOfFame",
            translationKey: "menuItem.hallOfFame",
            redirectTo: { path: "hallOfFame" }
        },
        {
            id: "faq",
            translationKey: "menuItem.faq",
            redirectTo: { path: "faq" }
        }
    ]
};
