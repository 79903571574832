import { getRoutes, RouteConfigType } from "@energylab/shared-components/routes"
import CONTENTS_ROUTES from "./routes/contents";
import CHARITY_ROUTES from "./routes/charity";
import HALL_OF_FAME_ROUTES from "./routes/hallOfFame";
import NEWS_ROUTES from "./routes/news";


const locales = ["en", "fr", "de", "es", "it", "hu", "pl"];
const defaultLocale = "en";

export const routeConfig: RouteConfigType = {
    locales,
    defaultLocale,
    routes: {
        private: getRoutes([
            ...CONTENTS_ROUTES,
            NEWS_ROUTES,
            CHARITY_ROUTES,
            HALL_OF_FAME_ROUTES,
        ])
    },
    redirects: [],
    hideNavigationWhenLoggedOut: true
};
