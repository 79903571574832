import { LeaderboardConfig } from "@energylab/shared-components/constants/config/leaderboardConfig";
import environment from "environment";
import { entities } from "../../constants/entities";

const modifyEntityFormat = (entities) => {
    return entities.map(entity => {
        const { label, ...entityWithoutLabel } = entity
        return {...entityWithoutLabel, labelId: label }
    })
}

const modifyEntitiesWithChildren = (entities) => {
    const modifiedEntities = modifyEntityFormat(entities)
    modifiedEntities.forEach(entity  => {
        if (entity.children) {
            entity.children = modifyEntityFormat(entity.children)
        }
    })
    return modifiedEntities
}

export const leaderboardConfig: LeaderboardConfig = {
    pageSize: 10,
    from: 1707177600000,
    to: 1738799999000,
    skipFields: ["changement"],
    ...(environment.showUserSearchFilters
        ? {
            filterOptions: [
                {
                    fields: ["profile.entity"],
                    options: modifyEntitiesWithChildren(entities),
                    isCascader: true
                }
            ]
        }
        : {}
    )
};
