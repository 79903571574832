export const dashboardPlacesConfig = {
    ambassadors: [],
    articles: ["articles_header"],
    benefits: ["benefits_header"],
    challenges: ["challenges_header"],
    events: ["events_header"],
    gallery: ["gallery_header"],
    homePage: ["dashboard_header", "dashboard_bridgestone"],
    leaderBoard: ["leaderboard_header"],
    mySpace: ["myspace_header"],
    mySpaceActivities: ["myactivities_header"],
    profile: ["profile_header"],
    webShop: ["webshop_header"]
};
