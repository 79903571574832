import { nationalities } from "@energylab/shared-components/constants/nationalities";
import { ConfigReducerState } from "@energylab/shared-components/redux/reducers/configReducer";
import { getBrowserLocale } from "@energylab/shared-components/utils/browserLocale";
import { whiteListSignUpConfig } from "@energylab/shared-components/constants/config/whiteListSignUpConfig";
import { auth } from "./constants/config/authConfig";
import { coinsConfig } from "./constants/config/coinsConfig";
import { challengeConfig } from "./constants/config/challengeConfig";
import { dashboardPlacesConfig } from "./constants/config/dashboardPlacesConfig";
import { commentsConfig } from "./constants/config/commentsConfig";
import { contactConfig } from "./constants/config/contactConfig";
import { contentConfig } from "./constants/config/contentConfig";
import { eventsConfig } from "./constants/config/eventsConfig";
import { footerConfig } from "./constants/config/footerConfig";
import { galleryConfig } from "./constants/config/galleryConfig";
import { leaderboardConfig } from "./constants/config/leaderboardConfig";
import { likesConfig } from "./constants/config/likesConfig";
import { newUsersConfig } from "./constants/config/newUsersConfig";
import { notificationsConfig } from "./constants/config/notificationsConfig";
import { providers } from "./constants/config/providers";
import { routeConfig } from "./constants/config/routeConfig";
import { seo } from "./constants/config/seoConfig";
import { socialConfig } from "./constants/config/socialConfig";
import { socialShareConfig } from "./constants/config/socialShareConfig";
import { webshopConfig } from "./constants/config/webshopConfig";
import { entityConfig } from "./constants/config/entityConfig";
import { manualActivityConfig } from "./constants/config/manualActivityConfig";
import { registerConfig } from "./constants/config/registerConfig";
import { profileConfig } from "./constants/config/profileConfig";
import { navbarConfig } from "./constants/config/navbarConfig";

export const config: ConfigReducerState = {
    password: {
        requireOldPassword: false,
        minLength: 8,
        requireLetter: true,
        requireUpperCase: true,
        requireLowerCase: true,
        requireNumber: true,
        requireSpecialCharacter: false,
        allowedSpecialCharacters: [],
        lockOut: {
            enabled: false,
            time: 0,
            attempts: 10
        }
    },
    mustAcceptPolicies: ["privacy", "disclaimer", "cookyPolicy"],
    footerConfig,
    socialShareConfig,
    registerConfig,
    profileConfig,
    leaderboardConfig,
    socialConfig,
    ambassadorImage: "",
    auth,
    commentsConfig,
    challengeConfig,
    eventsConfig,
    contentConfig,
    contactConfig,
    companyLogo: "https://res.cloudinary.com/energylab/image/upload/q_auto,f_auto/v1590996269/bridgestone/bridgestone-logo.png",
    companyName: "Bridgestone",
    dashboardPlacesConfig,
    dashboardTopPadding: "0px",
    defaultTimezone: "Europe/Brussels",
    defaultCountry: "Belgium",
    defaultNationality: "BEL",
    coinsConfig,
    likesConfig,
    webshopConfig,
    notificationsConfig,
    providers,
    jumbotronImage: "",
    localeDateFormat: "DD/MM/YYYY",
    routeConfig,
    navbarConfig,
    nationalities,
    selectedLanguage: getBrowserLocale(routeConfig.defaultLocale, routeConfig.locales),
    seo,
    uploadPreset: "bridgestone",
    newUsersConfig,
    hideSocialLogin: true,
    galleryConfig,
    whiteListSignUpConfig,
    entityConfig,
    manualActivityConfig,
    authLogo: "https://res.cloudinary.com/energylab/image/upload/v1612514351/bridgestone/bridgestone-logo_adaptions_09-02.png",
    requiredProfileFields: [
        "givenName",
        "familyName",
        "birthDate",
        "entity",
        "sex"
    ]
};
